import { useGeo } from '../api/queries'
import { regions } from '../api/constants'

const contactInfo = {
  email: 'info@ishausa.org',
  phone: '(931)-668-1900',
}

export default contactInfo

export const useShowContactInfo = () => {
  const { geo, isSuccess } = useGeo()
  return (
    !isSuccess ||
    geo.region === regions.northAmerica ||
    geo.region === regions.southAmerica
  )
}
