import React from 'react'
import { Icon } from '@chakra-ui/react'

const FacebookIcon = (props) => (
  <Icon viewBox='0 0 9.86 19.75' {...props}>
    <path
      fill={'currentColor'}
      d='M2.88 19.75v-8.47c0-.46 0-.38-.38-.38H.2c-.2 0-.2 0-.2-.21V7.61c0-.2 0-.2.21-.2h2.43c.24 0 .24 0 .24-.25v-3a4.42 4.42 0 011-2.71A3.78 3.78 0 016.14.12 6.35 6.35 0 017.88 0l1.23.08h.56c.17 0 .18 0 .18.2v2.76c0 .17 0 .18-.17.18H7.93a2.24 2.24 0 00-.52.06 1.2 1.2 0 00-1 1.21v2.65c0 .2 0 .2.21.2H9.4c.15 0 .15 0 .13.16l-.33 2.74c0 .17-.06.33-.07.5s-.06.16-.18.16H6.52c-.15 0-.16 0-.17.17v8.54a.66.66 0 000 .14z'
    />
  </Icon>
)

export default FacebookIcon
