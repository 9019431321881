import { useQuery } from 'react-query'
import axios from 'axios'
import { getMainAPIUrl } from '../utils/apiUtils'
import { regions } from './constants'

export function usePreorderRetailers() {
  return useQuery('preorderRetailers', () =>
    axios
      .get(`${getMainAPIUrl()}/edgeapi/karma-retailers`)
      .then((res) => res.data)
  )
}

export function useBookReleaseDate({ yearFormat = 'numeric' } = {}) {
  const { data } = usePreorderRetailers()
  const releaseDate = data?.retailers?.releaseDate

  if (!releaseDate) {
    return null
  }

  let [year, month, day] = releaseDate.split('-')

  year = parseInt(year)
  month = parseInt(month) - 1 // Month is 0-indexed
  day = parseInt(day)

  return new Date(year, month, day).toLocaleString('en-US', {
    month: 'long',
    day: '2-digit',
    ...(yearFormat && { year: yearFormat }),
  })
}

export function useIsBookReleased() {
  // Book is released on all regions
  return true
}

export const useGeo = () => {
  const {
    data: {
      geo = { city: null, country: null, state: null },
      region = null,
    } = {},
    ...other
  } = usePreorderRetailers()

  return { geo: { ...geo, region }, ...other }
}

export const getGeoValue = ({ data, country, region }) =>
  country in data ? data[country] : region in data ? data[region] : data.US

export const useContactInformation = () => {
  const {
    geo: { country, region },
  } = useGeo()

  return getGeoValue({
    data: {
      US: { email: 'info@ishausa.org', phone: '(931) 668-1900' },
      [regions.oceania]: {
        email: 'australia@ishafoundation.org',
        phone: '1300 24 4742',
      },
    },
    country,
    region,
  })
}
