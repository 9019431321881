import React from 'react'
import { Icon } from '@chakra-ui/react'

const YoutubeIcon = (props) => (
  <Icon viewBox='0 0 26.99 18.87' {...props}>
    <path d='M27,8.53v1.74a2.74,2.74,0,0,0,0,.28c0,.81-.07,1.62-.12,2.42a19.32,19.32,0,0,1-.32,2.62,3.43,3.43,0,0,1-1.43,2.25,3.8,3.8,0,0,1-1.95.64c-1.12.11-2.24.2-3.36.26s-2.38.1-3.56.11c-3.43,0-6.86,0-10.28-.2-.87-.06-1.74-.15-2.6-.27A3.3,3.3,0,0,1,.51,15.74a13.7,13.7,0,0,1-.3-1.92C.11,12.52,0,11.21,0,9.9S.07,7.27.13,6A14.58,14.58,0,0,1,.52,3.08,3.23,3.23,0,0,1,3.13.52,22.41,22.41,0,0,1,5.85.21c1-.07,2-.1,3-.13C10.49,0,12.14,0,13.78,0c2.57,0,5.13,0,7.69.25A20,20,0,0,1,23.8.53a3.23,3.23,0,0,1,2.6,2.4,13,13,0,0,1,.41,2.63c.07.86.09,1.73.13,2.6C27,8.28,27,8.41,27,8.53Zm-16.22.92V13.2c0,.32,0,.32.29.16l5.09-2.93,1.53-.89c.16-.09.15-.11,0-.21l-.09-.05L11.06,5.52c-.29-.17-.29-.17-.29.16Z' />
  </Icon>
)

export default YoutubeIcon
