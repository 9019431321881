import React from 'react'

export function HamIcon(props) {
  return (
    <svg viewBox='0 0 100 80' width='25' height='25' fill={'white'} {...props}>
      <rect width='90' height='10' />
      <rect y='30' width='90' height='10' />
      <rect y='60' width='90' height='10' />
    </svg>
  )
}
