import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import defaultSocialImage from '../../images/index/socialImage.jpg'

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        twitterUsername
      }
    }
  }
`

export default function MetaTags(props) {
  const { pathname } = useLocation()
  const {
    site: {
      siteMetadata: {
        siteUrl,
        defaultTitle,
        defaultDescription,
        twitterUsername,
      },
    },
  } = useStaticQuery(query)

  let {
    title = defaultTitle,
    description = defaultDescription,
    image = defaultSocialImage,
  } = props

  image = siteUrl + image

  return (
    <Helmet title={title}>
      <meta name='description' content={description} />
      <meta name='image' content={image} />
      <meta property='og:url' content={`${siteUrl}${pathname}`} />
      {title && <meta property='og:title' content={title} />}

      {description && <meta property='og:description' content={description} />}
      {image && <meta property='og:image' content={image} />}

      <meta name='twitter:card' content='summary_large_image' />
      {twitterUsername && (
        <meta name='twitter:creator' content={twitterUsername} />
      )}
      {title && <meta name='twitter:title' content={title} />}
      {description && <meta name='twitter:description' content={description} />}
      {image && <meta name='twitter:image' content={image} />}
    </Helmet>
  )
}
