import React from 'react'
import { Icon } from '@chakra-ui/react'

const PinterestIcon = (props) => (
  <Icon viewBox='0 0 19.47 24.01' {...props}>
    <path d='M4.43,24a6.52,6.52,0,0,1-.16-.74c-.08-.82-.15-1.64-.2-2.46a9.6,9.6,0,0,1,.33-2.92c.54-2.11,1.06-4.23,1.59-6.34a.76.76,0,0,0,0-.47,4.94,4.94,0,0,1-.09-3.51A2.82,2.82,0,0,1,7.78,5.7a2,2,0,0,1,1.16,0,1.74,1.74,0,0,1,1.25,1.46A3.79,3.79,0,0,1,10,9c-.28,1-.59,1.92-.88,2.89a4.49,4.49,0,0,0-.21,1,1.92,1.92,0,0,0,1.14,2,2.88,2.88,0,0,0,2.14.16,4.11,4.11,0,0,0,2.07-1.27,6.82,6.82,0,0,0,1.61-3.23,7.32,7.32,0,0,0,.19-2,6.43,6.43,0,0,0-1.41-4,5.39,5.39,0,0,0-2.92-1.81A7.84,7.84,0,0,0,6.89,3a6.25,6.25,0,0,0-4,4.81,5.17,5.17,0,0,0,.54,3.65,5.35,5.35,0,0,0,.47.67.57.57,0,0,1,.12.53c-.12.47-.23.94-.36,1.4s-.24.51-.67.29a4.29,4.29,0,0,1-1.5-1.28A7,7,0,0,1,.08,9.78a8.34,8.34,0,0,1,3.61-8A10.23,10.23,0,0,1,7.54.23,12.94,12.94,0,0,1,10.25,0a10.16,10.16,0,0,1,5.43,1.6,8,8,0,0,1,3.46,4.52,8.14,8.14,0,0,1,.31,3,10,10,0,0,1-.86,3.57,8,8,0,0,1-3.61,4,7,7,0,0,1-3.76.81,4.24,4.24,0,0,1-2.52-1A5,5,0,0,1,8.19,16l-.13-.12c0,.05-.05.11-.07.17l-.33,1.19c-.18.65-.35,1.3-.53,1.95a12.62,12.62,0,0,1-1.41,3.08c-.3.5-.63,1-1,1.48a3.65,3.65,0,0,1-.24.31Z' />
  </Icon>
)

export default PinterestIcon
