import React from 'react'
import { Icon } from '@chakra-ui/react'

const InstagramIcon = (props) => (
  <Icon viewBox='0 0 22.68 22.68' {...props}>
    <path d='M0,16.26V6.38l.06-.44A6.74,6.74,0,0,1,3.61.79,6.9,6.9,0,0,1,6.91,0h8.86A6.91,6.91,0,0,1,19,.73,6.66,6.66,0,0,1,21.56,3a6.78,6.78,0,0,1,1.12,3.83V15.8a7.26,7.26,0,0,1-.06.93,6.55,6.55,0,0,1-1,2.86A6.76,6.76,0,0,1,19,21.92a6.56,6.56,0,0,1-2.67.72.58.58,0,0,0-.19,0H6.56l-.28-.05a5.63,5.63,0,0,1-1.67-.33A6.77,6.77,0,0,1,.13,17.15C.08,16.85,0,16.55,0,16.26Zm2.28-4.92h0c0,1.46,0,2.91,0,4.37a5.61,5.61,0,0,0,.06.92,4.37,4.37,0,0,0,2,3.07,4.55,4.55,0,0,0,2.59.72h8.72a6.5,6.5,0,0,0,1-.07,4.37,4.37,0,0,0,3.05-2,4.63,4.63,0,0,0,.71-2.61q0-4.39,0-8.77a5.57,5.57,0,0,0-.06-.91,4.36,4.36,0,0,0-2-3.08,4.52,4.52,0,0,0-2.56-.7H6.93a5.22,5.22,0,0,0-.88.07,4.41,4.41,0,0,0-3.47,2.8,4.58,4.58,0,0,0-.3,1.8Z' />
    <path d='M11.35,5.48a5.86,5.86,0,1,1-5.87,5.85A5.87,5.87,0,0,1,11.35,5.48Zm0,9.45a3.59,3.59,0,1,0-3.58-3.61A3.6,3.6,0,0,0,11.33,14.93Z' />
    <path d='M18.61,5.52a1.4,1.4,0,1,1-1.38-1.4A1.39,1.39,0,0,1,18.61,5.52Z' />
  </Icon>
)

export default InstagramIcon
