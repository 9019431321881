import React from 'react'
import { Icon } from '@chakra-ui/react'

const TwitterIcon = (props) => (
  <Icon viewBox='0 0 20.78 17.05' {...props}>
    <path
      fill={'currentColor'}
      d='M20.78,2.07A9.07,9.07,0,0,1,19.6,3.44c-.25.24-.52.46-.8.68a.32.32,0,0,0-.13.3c0,.19,0,.39,0,.59a11.81,11.81,0,0,1-.61,3.59,12.56,12.56,0,0,1-2.56,4.49,11.53,11.53,0,0,1-5.13,3.37A12.75,12.75,0,0,1,7.86,17,10.56,10.56,0,0,1,6.73,17c-.42,0-.85,0-1.27,0a12.26,12.26,0,0,1-2.18-.37A11.84,11.84,0,0,1,.13,15.33L0,15.27l0,0,.07,0a1.92,1.92,0,0,1,.24,0,8.58,8.58,0,0,0,3.34-.45,8.66,8.66,0,0,0,2.4-1.28.28.28,0,0,0,.08-.11l-.13,0A4.3,4.3,0,0,1,2.41,11c-.08-.15-.13-.31-.2-.47s0-.1.08-.09l.41,0a4.06,4.06,0,0,0,1.2-.09h0L4,10.28a.26.26,0,0,0-.09,0c-.22-.08-.46-.13-.68-.23A4.29,4.29,0,0,1,.71,6.77c0-.2,0-.4,0-.6s0-.12.13-.07a4,4,0,0,0,1.1.37c.2,0,.4,0,.59.08l.06,0a.19.19,0,0,0,0-.07l-.11-.09A4.23,4.23,0,0,1,.71,3.42,4.19,4.19,0,0,1,1.19.92c.06-.11.09-.1.16,0s.32.37.49.55A12.09,12.09,0,0,0,4.71,3.71a12.2,12.2,0,0,0,3,1.23,14.55,14.55,0,0,0,2.08.34h0c.27,0,.33.06.29-.3A4.2,4.2,0,0,1,11.92.77,4,4,0,0,1,14.08,0a4.28,4.28,0,0,1,3.12,1,.9.9,0,0,1,.1.09.42.42,0,0,0,.54.15A9.07,9.07,0,0,0,20.09.41l.1,0s0,.08,0,.11a4.1,4.1,0,0,1-1.57,2c-.06,0-.12.12-.18.18l.24,0a8.89,8.89,0,0,0,1.9-.51l.22-.07Z'
    />
  </Icon>
)

export default TwitterIcon
