import React from 'react'
import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { SocialLinksRow } from '../elements/socialLinks'
import { BaseLink } from '../elements/link'
import contactInfo, { useShowContactInfo } from '../../utils/contactInfo'
import PenguinLogo from '../elements/penguinLogo'

const Footer = ({ showPenguinLogo = true }) => {
  const showContactInfo = useShowContactInfo()
  return (
    <Box>
      <Container pt={8} pb={8}>
        {showPenguinLogo && (
          <Flex justifyContent={'center'} pb={5}>
            <PenguinLogo />
          </Flex>
        )}

        {showContactInfo && (
          <Box pb={10} textAlign={'center'}>
            <Heading textAlign={'center'} mb={3}>
              Contact us
            </Heading>
            <Link
              as={'a'}
              href={
                'https://support.ishausa.org/hc/en-us/sections/360013204012-Karma-Book'
              }
              isExternal={true}
            >
              Help Center
            </Link>
            <HStack justifyContent={'center'} spacing={6} mt={4}>
              <Link as={'a'} href={`mailto:${contactInfo.email}`}>
                {contactInfo.email}
              </Link>
              <Link as={'a'} href={`tel:${contactInfo.phone}`}>
                {contactInfo.phone}
              </Link>
            </HStack>
            <Text mt={4}>
              For media inquiries:{' '}
              <Link href={'mailto:media@ishausa.org'}>media@ishausa.org</Link>
            </Text>
          </Box>
        )}

        <SocialLinksRow justifyContent={'center'} />
      </Container>

      <Box backgroundColor={'#6E6D7C'} padding={'10px'}>
        <Container maxWidth={'container.lg'}>
          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box mb={{ base: 2, md: 0 }}>
              <StaticImage
                src={'../../images/footer/isha-logo-footer.png'}
                alt={'Isha'}
                objectFit={'contain'}
              />
            </Box>

            <Flex
              flexDirection={'column'}
              justifyContent={'space-around'}
              alignItems={['center', 'flex-end']}
            >
              <Box fontSize={'sm'} color={'white'}>
                <Text as={'span'} style={{ textTransform: 'uppercase' }}>
                  © 2021 sadhguru all rights reserved
                </Text>
                <Text as={'span'}>{' | '}</Text>
                <Text
                  as={BaseLink}
                  href={'https://isha.sadhguru.org/privacy'}
                  style={{ textTransform: 'uppercase' }}
                >
                  privacy policy
                </Text>
                <Text as={'span'}>{' | '}</Text>
                <Text
                  as={BaseLink}
                  href={'/terms-conditions/'}
                  style={{ textTransform: 'uppercase' }}
                >
                  terms & conditions
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default Footer
