import {
  FacebookIcon,
  InstagramIcon,
  PinterestIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../icons'
import { HStack, Link } from '@chakra-ui/react'
import React from 'react'

const socialMediaLinks = [
  {
    id: 'instagram',
    Icon: InstagramIcon,
    href: 'https://www.instagram.com/sadhguru',
  },
  {
    id: 'facebook',
    Icon: FacebookIcon,
    href: 'https://www.facebook.com/sadhguru/',
  },
  {
    id: 'twitter',
    Icon: TwitterIcon,
    href: 'https://twitter.com/SadhguruJV',
  },
  {
    id: 'youtube',
    Icon: YoutubeIcon,
    href: 'https://www.youtube.com/channel/UCcYzLCs3zrQIBVHYA1sK2sw',
  },
  {
    id: 'pinterest',
    Icon: PinterestIcon,
    href: 'https://www.pinterest.com/Sadhguru/',
  },
]

export default socialMediaLinks

export const SocialLinksRow = ({
  spacing = 6,
  color = 'blue',
  size = '1.5em',
  ...props
}) => (
  <HStack spacing={spacing} {...props}>
    {socialMediaLinks.map(({ id, Icon, href }) => (
      <Link key={id} href={href} isExternal>
        <Icon w={size} h={size} fill={color} />
      </Link>
    ))}
  </HStack>
)
