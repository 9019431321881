import React, { useEffect, useState } from 'react'
import { Button, Grid, Heading, HStack, Link, useTheme } from '@chakra-ui/react'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import { HamIcon } from '../../page/icons'
import { SocialLinksRow } from '../../elements/socialLinks'
import { isoUrl, liveEventsUrl, orderUrl } from './urls'
import { NavDrawer } from './drawer'
import { BaseLink } from '../../elements/link'
import { useGeo } from '../../../api/queries'
import { regions } from '../../../api/constants'

function LiveEventsButton({ display }) {
  return (
    <Button
      as={BaseLink}
      href={liveEventsUrl}
      px={[1, 3, 4]}
      variant={'outline'}
      fontWeight={'normal'}
      border={'1px solid white'}
      display={display}
      fontSize={['12px', 'inherit']}
      height={[7, 9]}
      background={'transparent'}
      /* Prevent default hover effect */
      _hover={{}}
    >
      EVENTS
    </Button>
  )
}

function NavItemsLeft(props) {
  const { onRequestOpen } = props
  const displayProps = { base: 'none', lg: 'block' }
  const {
    geo: { region },
  } = useGeo()

  return (
    <HStack
      alignItems={'center'}
      justifyContent={'flex-start'}
      spacing={{ base: 4, xl: 6 }}
      textTransform={'uppercase'}
    >
      <HamIcon onClick={onRequestOpen} style={{ cursor: 'pointer' }} />
      <LiveEventsButton display={{ base: 'none', lg: 'inline-flex' }} />
      <Link as={BaseLink} href={orderUrl} display={displayProps}>
        ORDER NOW
      </Link>
      <Link as={BaseLink} href={'/media'} display={displayProps}>
        Media
      </Link>
      {region === regions.northAmerica && (
        <Link
          as={BaseLink}
          href={'/shop'}
          display={{ base: 'none', xl: 'block' }}
        >
          Shop
        </Link>
      )}
    </HStack>
  )
}

function NavItemsRightLargeBP() {
  const displayProps = { base: 'none', lg: 'block' }
  return (
    <HStack alignItems={'center'} justifyContent={'flex-end'}>
      <SocialLinksRow
        color={'white'}
        size={'1.1em'}
        spacing={6}
        pr={8}
        mb={'1px'}
        display={displayProps}
      />
      <Link href={isoUrl} target={'_blank'} display={displayProps}>
        BACK TO ISHA
      </Link>
      <LiveEventsButton display={{ base: 'inline-flex', lg: 'none' }} />
    </HStack>
  )
}

const MotionGrid = motion(Grid)
const MotionHeading = motion(Heading)

const useFadeController = (fade) => {
  const [fadeDistance, setFadeDistance] = useState(300)

  const calculateFadeDistance = () => {
    const headerSection = document.getElementById('header-section')
    if (headerSection) {
      setFadeDistance(headerSection.getBoundingClientRect().height)
    }
  }

  useEffect(() => {
    calculateFadeDistance()
  }, [])

  const theme = useTheme()
  const blueColor = theme.colors.blue

  const { scrollY } = useViewportScroll()
  const opacity = useTransform(scrollY, [0, fadeDistance], [0, 1])
  const backgroundColor = useTransform(
    opacity,
    [0, 1],
    [`${blueColor}00`, blueColor]
  )

  return fade
    ? {
        backgroundColor,
        opacity,
      }
    : { backgroundColor: blueColor, opacity: 1 }
}

const NavbarContent = ({ onRequestOpen, backgroundFade = true }) => {
  const { backgroundColor, opacity } = useFadeController(backgroundFade)
  return (
    <MotionGrid
      style={{
        backgroundColor,
        filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, .8))',
      }}
      w={'100%'}
      h={'68px'}
      alignItems={'center'}
      justifyContent={'center'}
      position={backgroundFade ? 'fixed' : 'sticky'}
      top={0}
      zIndex={2}
      color={'white'}
      templateColumns={'1fr min-content 1fr'}
      pl={[4, 8]}
      pr={[2, 8]}
    >
      <NavItemsLeft onRequestOpen={onRequestOpen} />
      <BaseLink href={'/'}>
        <MotionHeading
          as={'h2'}
          textStyle={'heading'}
          fontSize={['2rem', '2.8rem']}
          style={{ opacity }}
        >
          KARMA
        </MotionHeading>
      </BaseLink>
      <NavItemsRightLargeBP />
    </MotionGrid>
  )
}

function Navbar({ backgroundFade }) {
  const [navOpen, setNavOpen] = useState(false)

  const handleLinkClick = () => {
    setNavOpen(false)
  }

  return (
    <>
      <NavbarContent
        onRequestOpen={() => setNavOpen(true)}
        backgroundFade={backgroundFade}
      />

      <NavDrawer
        onClose={() => setNavOpen(false)}
        open={navOpen}
        onClick={handleLinkClick}
      />
    </>
  )
}

export default Navbar
