import React from 'react'
import { useGeo } from '../../api/queries'
import { StaticImage } from 'gatsby-plugin-image'
import { regions } from '../../api/constants'

function PenguinLogo() {
  const {
    geo: { region },
  } = useGeo()
  return region === regions.india ? (
    <StaticImage
      src={'../../images/common/penguinLogoIndia.png'}
      alt={'Penguin Random House'}
      width={120}
    />
  ) : (
    <StaticImage
      src={'../../images/common/penguinLogo.png'}
      alt={'Penguin Random House'}
      width={210}
    />
  )
}

export default PenguinLogo
