import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Grid,
  Link,
  Text,
} from '@chakra-ui/react'
import { isoUrl, liveEventsUrl, orderUrl } from './urls'
import React from 'react'
import * as PropTypes from 'prop-types'
import { BaseLink } from '../../elements/link'
import contactInfo, { useShowContactInfo } from '../../../utils/contactInfo'
import { SocialLinksRow } from '../../elements/socialLinks'
import { useGeo, useIsBookReleased } from '../../../api/queries'
import { regions } from '../../../api/constants'

const DrawerLink = (props) => (
  <Link as={BaseLink} textTransform={'uppercase'} {...props} />
)

export function NavDrawer(props) {
  const {
    geo: { region },
  } = useGeo()
  const showContactInfo = useShowContactInfo()
  const isBookReleased = useIsBookReleased()

  return (
    <Drawer placement={'left'} onClose={props.onClose} isOpen={props.open}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Grid color={'#030040'} rowGap={4} marginTop={20}>
              <DrawerLink href={liveEventsUrl} onClick={props.onClick}>
                {region === regions.oceania ? 'EVENTS' : 'LIVE EVENTS'}
              </DrawerLink>
              <DrawerLink href={orderUrl} onClick={props.onClick}>
                {isBookReleased ? 'ORDER NOW' : 'PRE-ORDER NOW'}
              </DrawerLink>
              <DrawerLink href='/#about-book' onClick={props.onClick}>
                ABOUT THE BOOK
              </DrawerLink>
              <DrawerLink href='/#quiz' onClick={props.onClick}>
                KARMA QUIZ
              </DrawerLink>
              <DrawerLink href='/media' onClick={props.onClick}>
                MEDIA
              </DrawerLink>
              <DrawerLink href='/shop' onClick={props.onClick}>
                SHOP
              </DrawerLink>
              <DrawerLink
                href={isoUrl}
                onClick={props.onClick}
                target={'_blank'}
              >
                BACK TO ISHA
              </DrawerLink>
              {showContactInfo && (
                <>
                  <Box borderTop={'solid 1px'} borderColor={'blue'} />

                  <Text textTransform={'uppercase'}>Contact Us</Text>
                  <DrawerLink
                    display={'block'}
                    mt={1}
                    isExternal={true}
                    href={`https://support.ishausa.org/hc/en-us/sections/360013204012-Karma-Book`}
                    onClick={props.onClick}
                  >
                    Help Center
                  </DrawerLink>

                  <DrawerLink
                    display={'block'}
                    mt={1}
                    href={`mailto:${contactInfo.email}`}
                    onClick={props.onClick}
                  >
                    {contactInfo.email}
                  </DrawerLink>
                  <DrawerLink
                    display={'block'}
                    mt={1}
                    href={`tel:${contactInfo.phone}`}
                    onClick={props.onClick}
                  >
                    {contactInfo.phone}
                  </DrawerLink>
                </>
              )}

              <Text textTransform={'uppercase'}>
                Media inquiries:{' '}
                <Link href='mailto:media@ishausa.org'>media@ishausa.org</Link>
              </Text>

              <SocialLinksRow mt={4} display={{ base: null, lg: 'none' }} />
            </Grid>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

NavDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onClick: PropTypes.func,
}
