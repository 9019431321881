export const regions = {
  northAmerica: 'North America',
  southAmerica: 'South America',
  india: 'IN',
  europe: 'EU',
  africa: 'Africa',
  apac: 'APAC',
  centralAsia: 'Central Asia',
  middleEast: 'Arabia',
  russia: 'Russia',
  uk: 'UK',
  oceania: 'Oceania',
}
