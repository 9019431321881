import React, { forwardRef } from 'react'
import { Link as GatsbyLink, withPrefix, navigate } from 'gatsby'
import { Link } from '@chakra-ui/react'

export const TextLink = forwardRef((props, ref) => {
  return <Link as={BaseLink} ref={ref} variant={'text'} {...props} />
})

TextLink.displayName = 'TextLink'

export const BaseLink = forwardRef(
  ({ href, isExternal, onClick, children, ...props }, ref) => {
    // We assume all relative URLs point to other Gatsby pages
    const isGatsbyUrl = href && href[0] === '/'

    // GatsbyLink doesn't support URLs with ? or #, so use navigate instead
    // But maybe it does? The navigate call from @reach/router is
    // causing page not found error right now
    const shouldUseNavigate = false
    // isGatsbyUrl &&
    // (href.indexOf('?') !== -1 || href.indexOf('#') !== -1) &&
    // !isExternal

    const shouldUseATag = !isGatsbyUrl || isExternal || shouldUseNavigate

    const Component = shouldUseATag ? 'a' : GatsbyLink
    return (
      <Component
        {...(shouldUseATag ? { href } : { to: href })}
        {...(isExternal && { target: '_blank', rel: 'noopener noreferrer' })}
        onClick={(event) => {
          if (shouldUseNavigate) {
            event.preventDefault()
            navigate(isGatsbyUrl ? withPrefix(href) : href)
          }
          onClick && onClick(event)
        }}
        ref={ref}
        {...props}
      >
        {children}
      </Component>
    )
  }
)

BaseLink.displayName = 'BaseLink'
